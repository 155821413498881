export const sharedEnvironment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyCFCyF62JXiQ39DawQU2880Y2dzdBanepQ',
    authDomain: 'dixper-abae2.firebaseapp.com',
    databaseURL: 'https://dixper-abae2.firebaseio.com',
    storageURL: 'https://firebasestorage.googleapis.com',
    projectId: 'dixper-abae2',
    storageBucket: 'dixper-abae2.appspot.com',
    messagingSenderId: '398186278784',
    appId: '1:398186278784:web:9e91eccdf32ecc22',
    measurementId: 'G-HKEGKNYP0V',
  },
  googleCloud: {
    FONT_API: 'AIzaSyB3zlLj369ho9tJMbbfvvk0tybMprW3wxA',
    RECAPTCHA: '6LflQXQUAAAAAAVmHBxtGacp1kge4pUTFBGkSRsp',
  },
  sentry: {
    enable: true,
    tracesSampleRate: 0.1,
    autoSessionTracking: true,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tracePropagationTargets: ['https://challenges-dot-dixper-abae2.uc.r.appspot.com', 'https://api-dot-dixper-abae2.uc.r.appspot.com'],
  },
  legals: {
    terms: 'https://www.notion.so/dixper/General-Terms-and-Conditions-c14543cb9585408a96f56f36ae9c7b07',
    privacy: 'https://www.notion.so/dixper/Privacy-Policy-46090bb679ac443f8b82fe2b08c8014b',
    notice: 'https://www.notion.so/dixper/Legal-Notice-fe5e92cf472647c396c22eb2b095b152',
    cookies: 'https://www.notion.so/dixper/Cookies-Policy-f793870d7fd34a8f8a86ef286eb3bc53',
  },
  host: 'https://dixper.gg',
  twitch: {
    clientId: 'ej6c8ie77mazml0k8ddqw1qu1zu7zs',
    clientSecret: 'tihhppuxoeous04f8gg6ja4d2pmtay',
    redirectUri: 'https://dixper.gg/',
    redirectUriStudio: 'https://dixper.gg/login-studio',
  },
  youtube: {
    redirectUri: 'https://dixper.gg/u/accounts',
  },
  engine: 'sinanju',
  discordUrl: 'https://discord.gg/ctwzZVq',
  streamingPlatforms: {
    twitch: 'https://www.twitch.tv',
    twitchPlayer: 'https://player.twitch.tv',
    youtube: 'https://www.youtube.com',
  },
  socialMediaPlatforms: {
    twitch: 'https://www.twitch.tv',
    youtube: 'https://www.youtube.com',
    facebook: 'https://www.facebook.com',
    instagram: 'https://www.instagram.com',
    twitter: 'https://www.twitter.com',
    tiktok: 'https://www.tiktok.com',
  },
  api: {
    url: 'https://api.dixper.gg/api',
  },
  hosts: {
    widget: 'https://widgets.dixper.gg', // PROD
    challengesUi: 'https://challenges-ui-dot-dixper-abae2.uc.r.appspot.com/',
  },
  searchEngine: {
    host: 'https://search.dixper.gg',
    apiKey: '6e1509d03a774c44d79b549c862910d281f4dd70bc0dec0f0bb834fe4d4ea470',
  },
  ws: {
    url: 'https://api.dixper.gg',
    options: {
      path: '/websocket/socket.io',
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: 5,
      autoConnect: false,
      transports: ['polling', 'websocket'],
    },
  },

  notion: {
    assets: 'https://www.notion.so/dixper/Dixper-Asset-Gallery-f3aafdfefb744823a79b720643d4e448',
    helpCenter: 'https://dixper.notion.site/Dixper-Help-Center-5d58baa2a49e41f5aa313b140a29a122',
  },
  common: {
    enginePingInterval: 20000,
    enginePingTimeout: 10000,
  },
  braintree: {
    merchant_id: 'd265y89kw2wsrmy9',
  },
};
