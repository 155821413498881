export const ignoreErrors = [
  'request is not a function',
  'r is not a function',
  'internal',
  'ExpressionChangedAfterItHasBeenCheckedError',
  'Window closed',
  'ChunkLoadError: Loading chunk',
  'Error: Service workers are disabled or not supported',
  'AbortError: The play() request was interrupted by a call to pause().',
  'AbortError: The play() request was interrupted by a new load request',
  'Error: internal Error: internal at new e',
  'localStorage.getItem',
  'Error: kill ESRCH',
  'zoid destroyed all components',
  'Error: Component closed',
  'Window closed for postrobot_method before ack',
  'Request to post https://www.paypal.com',
  'The fetching process for the media resource was aborted by the user agent at the',
  'The play() request was interrupted by a call to pause()',
  'Maximum call stack size exceeded',
  'fullscreen error',
  'The operation was aborted.',
  'Network Error',
  'Window navigated away',
  'ENOENT, node_modules',
  'Component closed',
  'Non-Error promise rejection captured with value: Timeout',
  'Detected container element removed from DOM',
  'Uncaught (in promise): Unable to connect',
  'Uncaught (in promise): Login authentication failed',
  'Uncaught (in promise): Error adding runtime hooks:',
  "Cannot set property 'hostElement' of undefined",
  'Non-Error exception captured with keys:',
  'NS_ERROR_NOT_INITIALIZED',
  'identifier field isn’t set',
  'Permission denied to access property "apply"',
  'dixper-updater.exe ENOENT',
  'kill EPERM Error',
  "Cannot set properties of undefined (setting 'hostElement')",
  'x.hostElement=this.viewContainerRef.element.nativeElement',
  'AtomFrameHostMsg_Message_Sync',
  'Database deleted by request of the user',
  'dixper-updater.exe EACCES',
  'dixper-updater.exe ENOENT',
  'Blocked a frame with origin',
  'Permission denied to access property',
  'Timeout,; Zone:',
  'Unhandled Promise rejection',
  "Cannot set properties of null (setting 'hostElement')",
];
