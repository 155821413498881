import { APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { Router } from '@angular/router';
import { sharedEnvironment } from './../../../../../../environments/shared/environment';
import { LOGGER_SERVICE } from './logger.service';
// import { SentryErrorHandler } from '@sentry/angular-ivy';
import { SentryErrorHandler } from './sentry-error-handler';

import * as Sentry from '@sentry/angular-ivy';

export const SentryHandlerModule = [
  {
    provide: LOGGER_SERVICE,
    useValue: sharedEnvironment,
  },
  { provide: ErrorHandler, useClass: SentryErrorHandler },
  {
    provide: Sentry.TraceService,
    deps: [Router],
  },
  {
    provide: APP_INITIALIZER,
    useFactory: () => () => {},
    deps: [Sentry.TraceService],
    multi: true,
  },
];
