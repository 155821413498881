import { CommonLogsService } from './../../../../libs/shared/utils/src/lib/services/analytics/common-logs.service';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Actions } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dx-overlays-app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  constructor(
    translate: TranslateService,
    private location: Location,
    private actions: Actions,
    private router: Router,
    private commonLogsService: CommonLogsService,
  ) {
    document.cookie = 'cross-site-cookie=bar; SameSite=None; Secure';
    translate.addLangs(['es', 'en']);
    translate.setDefaultLang('es');
    const regExp = new RegExp('/en/');
    const languageToUse = regExp.exec(this.location.path()) !== null ? 'en' : 'es';
    translate.use(languageToUse);
  }

  public ngOnInit(): void {
    this.commonLogsService.watchReduxEvents(this.actions);
    this.commonLogsService.watchRouteEvents(this.router);
  }
}
