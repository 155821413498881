import { ignoreErrors } from './sentry-ignore-errors';
import { sharedEnvironment } from '../../../../../../environments/shared/environment';

import * as Sentry from '@sentry/angular-ivy';

export const SentryInitializator = (name, environment) => {
  if (environment.sentry.enable) {
    Sentry.init({
      environment: environment.production ? 'Production' : 'Development',
      release: `${name}@${environment.version}`,
      dsn: environment.sentry.dsn,
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.routingInstrumentation,
        }),
        new Sentry.Replay(),
      ],
      tracesSampleRate: sharedEnvironment.sentry.tracesSampleRate,
      autoSessionTracking: sharedEnvironment.sentry.autoSessionTracking,
      replaysSessionSampleRate: sharedEnvironment.sentry.replaysSessionSampleRate,
      replaysOnErrorSampleRate: sharedEnvironment.sentry.replaysOnErrorSampleRate,
      tracePropagationTargets: sharedEnvironment.sentry.tracePropagationTargets,
      ignoreErrors,
    });
  }
};
