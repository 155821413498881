import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalTranslationService {
  public localTranslations: any = {};

  public setLocalTranslations(lang: string, translations: unknown): void {
    try {
      if (localStorage && !!localStorage.getItem) {
        localStorage.setItem(`Dixper-translations-${lang}`, JSON.stringify(translations));
      } else {
        throw new Error(`[TRANSLATIONS] Local storage not available`);
      }
    } catch (error) {
      console.error(error);
    }
  }

  public getLocalTranslations(lang: string): void {
    try {
      if (localStorage && !!localStorage.getItem) {
        const stringifiedTranslations = localStorage.getItem(`Dixper-translations-${lang}`);
        this.localTranslations[lang] = JSON.parse(stringifiedTranslations);
      } else {
        throw new Error(`[TRANSLATIONS] Local storage not available`);
      }
    } catch (error) {
      console.error(error);
    }
  }
}
