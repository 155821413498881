import { Injectable } from '@angular/core';
import { GuardsCheckEnd, GuardsCheckStart, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Actions } from '@ngrx/effects';
import { EventsService } from './events.service';

@Injectable({
  providedIn: 'root',
})
export class CommonLogsService {
  constructor(private eventsService: EventsService) {}

  public watchReduxEvents(actions: Actions): void {
    // if (environment.production) {
    // actions.subscribe((action: Action) => {
    //   if (action['modalComponent']) {
    //     delete action['modalComponent'];
    //   }
    //   if (action['component']) {
    //     delete action['component'];
    //   }
    //   console.log(`🚦 ~ Redux ~  ${action.type}`, action);
    // });
    // }
  }

  public watchRouteEvents(router: Router): void {
    // if (environment.production) {
    router.events.pipe().subscribe((event) => {
      if (event instanceof NavigationStart) {
        console.log(`🚦 ~ Router ~ Navigation start: ${event.url}`);
      }
      if (event instanceof GuardsCheckStart) {
        console.log(`🚦 ~ Router ~ Guard check start: ${event.url}`);
      }
      if (event instanceof GuardsCheckEnd) {
        console.log(`🚦 ~ Router ~ Guard check end: ${event.url}`);
      }
      if (event instanceof NavigationEnd) {
        console.log(`🚦 ~ Router ~ Navigation end: ${event.url}`);
        this.eventsService.page('desktop-page', { url: event.url });
      }
    });
    // }
  }
}
