// eslint-disable-next-line @typescript-eslint/no-redeclare
import { LOCATION_INITIALIZED, Location } from '@angular/common';

import { Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export function appInitializerFactory(translate: TranslateService, injector: Injector, location: Location) {
  return () =>
    new Promise<any>((resolve: any) => {
      const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
      locationInitialized
        .then(() => {
          translate.addLangs(['es', 'en']);
          const langToSet = /\/en\//.exec(location.path()) !== null ? 'en' : 'es';
          translate.use(langToSet).subscribe(
            () => {
              // console.info(`Successfully initialized '${langToSet}' language.'`);
            },
            () => {
              console.error(`Problem with '${langToSet}' language initialization.'`);
              resolve(null);
            },
            () => {
              resolve(null);
            },
          );
        })
        .catch((error) => console.log(error));
    });
}
