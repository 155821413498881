/* eslint-disable @typescript-eslint/no-floating-promises */
import { Injectable } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { UserAnalyticsInterface, UserInterface } from '../../../../../managers/user-manager/src/lib/interfaces/user.interface';
import { LoginPlatformsEnum } from '../../../../../managers/user-manager/src/lib/models/login-platforms.enum';
import { IUserNavigator } from '../../interfaces/app.model';
import { EventsEnum } from './events.enum';
import { FirebaseAnalyticsService } from './firebase-analytics.service';
import { MicroserviceAnalyticsService } from './microservice-analytics.service';

@Injectable({ providedIn: 'root' })
export class EventsService {
  private version: string;

  private utms: { [x: string]: string } = {};

  private metadata: { [x: string]: unknown } = {};

  constructor(
    private route: ActivatedRoute,
    private firebaseAnalyticsService: FirebaseAnalyticsService,
    private microserviceAnalyticsService: MicroserviceAnalyticsService,
  ) {
    this.setUtm(this.route.snapshot.queryParams);
    this.getUserAgentIdentity();
  }

  public track(event: EventsEnum, properties: { [key: string]: any } = {}, userId?: string): void {
    try {
      const propertiesToSend = { ...properties, version: this.version, ...this.utms, ...this.metadata };

      Promise.all([
        this.firebaseAnalyticsService.track(event, propertiesToSend),
        this.microserviceAnalyticsService.track(event, propertiesToSend, userId),
      ]);

      console.log(`📃 ~ Event service ~ Track ~ ${event}`, properties);
    } catch (error) {
      // console.error(`📃 ~ Event service ~ Track Error ~ ${event}`, properties);
    }
  }

  public page(name: string, properties: { url: string }): void {
    // try {
    //   Promise.all([
    //     this.firebaseAnalyticsService.page(name, properties),
    //     this.microserviceAnalyticsService.page(name, properties),
    //   ]);
    //   console.log(`📃 ~ Event service ~ Page ~ ${name}`, properties);
    // } catch (error) {
    //   console.error(`📃 ~ Event service ~ Page Error ~ ${name}`, properties);
    // }
  }

  public identify(userId: string, userData: Partial<UserInterface>, params: { [key: string]: any } = {}): void {
    const userDataAnalytics = this.analyticsTransformIUser(userData);

    try {
      Promise.all([
        this.firebaseAnalyticsService.identify(userId, userDataAnalytics, params),
        this.microserviceAnalyticsService.identify(userId, userDataAnalytics, params),
      ]);
      console.log(`📃 ~ Event service ~ Identify ~ ${userId}`, userData, params);
    } catch (error) {
      // console.error(`📃 ~ Event service ~ IOdentify Error ~ ${userId}`, userData, params);
    }
  }

  public setMetadata(key: string, value: unknown): void {
    console.log('📃 ~ Event service ~ Set metadata', key, value);
    this.metadata[key] = value;
  }

  public unsetMetadata(key: string): void {
    console.log('📃 ~ Event service ~ Unset metadata', key);
    if (this.metadata[key]) {
      this.metadata[key] = null;
      delete this.metadata[key];
    }
  }

  public setUtm(urlParams: Params): void {
    if (urlParams.utm_source) {
      this.utms['utm_source'] = urlParams.utm_source;
    }
    if (urlParams.utm_medium) {
      this.utms['utm_medium'] = urlParams.utm_medium;
    }
    if (urlParams.utm_campaign) {
      this.utms['utm_campaign'] = urlParams.utm_campaign;
    }
    if (urlParams.utm_term) {
      this.utms['utm_term'] = urlParams.utm_term;
    }
    if (urlParams.utm_content) {
      this.utms['utm_content'] = urlParams.utm_content;
    }
  }

  public setVersion(version: string): void {
    console.log(`📃 ~ Event service ~ Set version ~ ${version}`);
    this.version = version;
  }

  private analyticsTransformIUser(userData: Partial<UserInterface>): UserAnalyticsInterface {
    let accounts = {};

    accounts = this.getAccountSyncData(LoginPlatformsEnum.TWITCH, userData?.accounts?.twitch, accounts);
    accounts = this.getAccountSyncData(LoginPlatformsEnum.YOUTUBE, userData?.accounts?.youtube, accounts);
    const syncedPlatforms = Object.keys(accounts);
    const activePlatform = this.getActivePlatform(syncedPlatforms, userData?.platform);
    return {
      uid: userData?.uid || '',
      country: userData?.country || '',
      email: userData?.email || '',
      img: userData?.img || '',
      key: userData?.key || '',
      name: userData?.name || '',
      lang: userData?.lang || '',
      nameInsensitive: userData?.nameInsensitive || '',
      platform: userData?.platform || '',
      roles: userData?.roles || {},
      accounts,
      achievements: {
        points: userData?.achievements?.points || 0,
      },
      notifications: userData?.notifications,
      signUpDate: userData?.signUpDate || '',
      syncedPlatforms,
      activePlatform,
    };
  }

  private getAccountSyncData(platform, streamPlatform, accounts) {
    return {
      ...accounts,
      [platform]: {
        followers: streamPlatform?.followers ?? null,
        email: streamPlatform?.email ?? null,
        id: streamPlatform?.id ?? null,
        name: streamPlatform?.name ?? null,
        sync: !!streamPlatform,
      },
    };
  }

  private getActivePlatform(syncedPlatforms, platform) {
    const activePlatform = platform;

    if (activePlatform === LoginPlatformsEnum.GOOGLE) {
      return LoginPlatformsEnum.YOUTUBE;
    }
    if (activePlatform !== LoginPlatformsEnum.GOOGLE && activePlatform !== LoginPlatformsEnum.YOUTUBE) {
      return LoginPlatformsEnum.TWITCH;
    }
    return activePlatform;
  }

  private getUserAgentIdentity(): void {
    const userAgent: IUserNavigator = { device: this.getDevice(), mobile: this.isMobile(), browser: this.getBrowser() };
    this.setMetadata('UserAgent', userAgent);
  }

  private isMobile(): boolean {
    return window.matchMedia('only screen and (max-width: 760px)').matches;
  }

  private getDevice(): string {
    const { userAgent } = navigator;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent)) {
      return 'tablet';
    }
    if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(userAgent)) {
      return 'mobile';
    }
    return 'desktop';
  }

  private getBrowser(): string {
    const { userAgent } = navigator;

    if (/chrome|chromium|crios/i.exec(userAgent)) {
      return 'chrome';
    }
    if (/firefox|fxios/i.exec(userAgent)) {
      return 'firefox';
    }
    if (/safari/i.exec(userAgent)) {
      return 'safari';
    }
    if (/opr\//i.exec(userAgent)) {
      return 'opera';
    }
    if (/edg/i.exec(userAgent)) {
      return 'edge';
    }

    return 'unknown browser';
  }
}
