import { ErrorHandler } from '@angular/core';

import * as Sentry from '@sentry/angular-ivy';

export class SentryErrorHandler implements ErrorHandler {
  public handleError(error: Error): void {
    console.error(`[SENTRY ERROR] ${error?.message}`, error);
    Sentry.captureException(error);
  }
}
