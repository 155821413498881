export enum LoginPlatformsEnum {
  TWITCH = 'twitch',
  GOOGLE = 'google',
  YOUTUBE = 'youtube',
  FACEBOOK = 'facebook',
  TROVO = 'trovo',
  TWITTER = 'twitter',
  PASSWORD = 'password',
  CUSTOM = 'customToken',
  OTHER = 'other',
}
