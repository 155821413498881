export enum ETranslatesApps {
  WEB = 'web',
  DESKTOP = 'desktop',
  CHALLENGES = 'challenges',
  DESKTOP_UPDATER = 'desktop-updater',
  ADMIN = 'admin',
  OVERLAYS = 'overlays',
  REMOTE_PLAY = 'remote-play',
  GLOBAL = 'global',
}
