import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

const routes: Routes = [
  { path: '404', redirectTo: 'v1/not-found' },
  {
    path: 'v1/:lang/:streamerId/goal',
    loadChildren: () => import('./views/goal/goal/goal.module').then((m) => m.GoalModule),
  },
  {
    path: 'v1/:lang/:streamerId/monthly-goal',
    loadChildren: () => import('./views/monthly-goal/monthly-goal.module').then((m) => m.MonthlyGoalModule),
  },
  {
    path: 'v1/:lang/:streamerId/ranking',
    loadChildren: () => import('./views/ranking/ranking.module').then((m) => m.RankingModule),
  },
  {
    path: 'v1/:lang/:streamerId/dixperathon',
    loadChildren: () => import('./views/dixperathon-overlay/dixperathon-overlay.module').then((m) => m.DixperathonOverlayModule),
  },
  {
    path: 'v2/:lang/:streamerId/challenges/queue-alert',
    loadChildren: () =>
      import('./views/challenges/challenge-queue-alert/challenge-queue-alert.module').then((m) => m.ChallengeQueueAlertModule),
  },
  {
    path: 'v3/:lang/:streamerId/challenges',
    loadComponent: () => import('./views/challenges-v2/challenges-v2.component').then((m) => m.ChallengesV2Component),
  },

  { path: 'v1', loadChildren: () => import('./views/layout/layout.module').then((m) => m.LayoutModule) },
  { path: '**', redirectTo: 'v1/not-found' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
