import { Inject, Injectable, InjectionToken } from '@angular/core';

import * as Sentry from '@sentry/angular-ivy';

export const LOGGER_SERVICE: InjectionToken<string> = new InjectionToken<string>('logger.service');

@Injectable({ providedIn: 'root' })
export class LoggerService {
  constructor(@Inject(LOGGER_SERVICE) private environment) {
    if (this.environment.sentry.enable) {
      this.enableSentryLogger();
    }
  }

  private enableSentryLogger() {
    console.info = (...messages) => {
      this.addSentryBreadCrumb('debug', messages);
    };

    console.log = (...messages) => {
      this.addSentryBreadCrumb('log', messages);
    };

    console.warn = (...messages) => {
      this.addSentryBreadCrumb('warn', messages);
    };

    // console.error = (...messages) => {
    //   this.addSentryBreadCrumb('error', messages);
    // };
  }

  private addSentryBreadCrumb(level, messages) {
    let stringifyMessage;

    const messageLength = !!(messages?.length > 1);
    const messageIsNotString = !!(messages && typeof messages[0] !== 'string');

    if (messageLength || messageIsNotString) {
      if (typeof messages === 'object') {
        stringifyMessage = this.simpleStringify(messages);
      } else {
        stringifyMessage = JSON.stringify(messages);
      }
    } else {
      stringifyMessage = JSON.stringify(messages[0]);
    }

    const categories = stringifyMessage ? stringifyMessage.match(/\[(?:\[??[^[]*?\])/) : null;
    const category: string = categories ? categories[0] : 'GENERAL';
    const message: string = stringifyMessage ? stringifyMessage.replace(`${category} `, '') : stringifyMessage;

    this.sendBreadCrumb('default', level, category, message, null);

    if (level === 'error') {
      Sentry.captureException(messages.originalError || messages);
    }
  }

  private sendBreadcrumbForEachMessage(level, category, messages) {
    messages.forEach((message, i) => {
      if (typeof message !== 'string') {
        message = JSON.stringify(message);
      }
      if (i === 0) {
        message = category ? message.replace(`${category} `, '') : message;
      }
      console.info(`msg ${i} ${message}`);

      this.sendBreadCrumb('default', level, category, message, null);
    });
  }

  private sendBreadCrumb(type, level, category, message, data) {
    if (message) {
      const breadCrumb: Sentry.Breadcrumb = {
        type,
        level,
        category,
        message,
        data,
      };
      Sentry.addBreadcrumb(breadCrumb);
    }
  }

  private simpleStringify(object: object): string {
    if (object && typeof object === 'object') {
      object = this.copyWithoutCircularReferences([object], object);
    }
    return JSON.stringify(object);
  }

  private copyWithoutCircularReferences(references, object): object {
    const cleanObject = {};
    Object.keys(object).forEach((key) => {
      const value = object[key];
      if (value && typeof value === 'object') {
        if (references.indexOf(value) < 0) {
          references.push(value);
          cleanObject[key] = this.copyWithoutCircularReferences(references, value);
          references.pop();
        } else {
          cleanObject[key] = '###_Circular_###';
        }
      } else if (typeof value !== 'function') {
        cleanObject[key] = value;
      }
    });
    return cleanObject;
  }
}
