import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { UserAnalyticsInterface } from '../../../../../managers/user-manager/src/lib/interfaces/user.interface';
import firebase from 'firebase/app';
import 'firebase/analytics';

@Injectable({ providedIn: 'root' })
export class FirebaseAnalyticsService {
  private isSupported = false;

  constructor(private fun: AngularFireFunctions) {
    firebase.analytics.isSupported().then((supported) => {
      this.isSupported = supported;
    });
  }

  public async track(event: string, properties: { [key: string]: any } = {}): Promise<void> {
    try {
      if (!this.isSupported) {
        return;
      }

      firebase.analytics().logEvent(event, properties);
    } catch (error) {
      console.error(`📃 ~ FirebaseAnalyticsService service ~ Track Error ~ ${event}`, properties);
    }
    return;
  }

  public async page(name: string, properties: { url: string }): Promise<void> {
    try {
      if (!this.isSupported) {
        return;
      }
      firebase.analytics().setCurrentScreen(`${properties.url}`);
    } catch (error) {
      console.error(`📃 ~ FirebaseAnalyticsService service ~ Page Error ~ ${event}`, properties);
    }
    return;
  }

  public async identify(userId: string, userData: UserAnalyticsInterface, params: any = {}): Promise<void> {
    if (!this.isSupported) {
      return;
    }
    if (!userId || !userData) {
      throw new Error('userId and userData are required');
    }
    try {
      const eventToSend = { userData, params };
      firebase.analytics().logEvent('identify', eventToSend);
      firebase.analytics().setUserId(userId);
      firebase.analytics().setUserProperties(eventToSend);
    } catch (error) {
      console.error(`📃 ~ FirebaseAnalyticsService service ~ Identify Error ~ ${event}`, userId, userData);
    }
    return;
  }
}
