import { Location, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabase, AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireFunctionsModule, REGION } from '@angular/fire/functions';
import { AngularFirePerformanceModule } from '@angular/fire/performance';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { environment } from '@environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { appInitializerFactory } from '@services/translates-initializer';
import { sharedEnvironment } from './../../../../environments/shared/environment';
import { SentryHandlerModule } from './../../../../libs/shared/utils/src/lib/idk/sentry-handler.module';
import { SentryInitializator } from './../../../../libs/shared/utils/src/lib/idk/sentry-initializator';
import { ETranslatesApps } from './../../../../libs/shared/utils/src/lib/interfaces/utils/translates-apps.enum';
import { LocalTranslationService } from './../../../../libs/shared/utils/src/lib/services/localTranslationService.service';
import { TranslateApiLoader } from './../../../../libs/shared/utils/src/lib/services/translateLoader.service';

import localeEs from '@angular/common/locales/es';

registerLocaleData(localeEs, 'es');
SentryInitializator(`dixper-overlays`, environment);

export function HttpLoaderFactory(realtimedb: AngularFireDatabase, localTranslationService: LocalTranslationService): TranslateApiLoader {
  return new TranslateApiLoader(realtimedb, localTranslationService, [ETranslatesApps.OVERLAYS, ETranslatesApps.GLOBAL]);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    AngularFireModule.initializeApp(sharedEnvironment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireDatabaseModule,
    AngularFireFunctionsModule,
    AngularFirePerformanceModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [AngularFireDatabase, LocalTranslationService],
      },
    }),
    StoreModule.forRoot([], {
      runtimeChecks: {
        strictStateImmutability: false, // HACK: necessary for modal manager to work
        strictActionImmutability: false, // HACK: necessary for modal manager to work
      },
    }),
    EffectsModule.forRoot([]),
    // StoreDevtoolsModule.instrument({
    //   maxAge: 250,
    //   logOnly: environment.production,
    // }),
  ],
  providers: [
    { provide: REGION, useValue: 'us-central1' },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector, Location],
      multi: true,
    },
    ...(environment.sentry.enable ? [SentryHandlerModule] : []),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
