import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import { sharedEnvironment } from '../../../../../../../environments/shared/environment';
import { UserAnalyticsInterface } from '../../../../../managers/user-manager/src/lib/interfaces/user.interface';

@Injectable({ providedIn: 'root' })
export class MicroserviceAnalyticsService {
  constructor(private httpClient: HttpClient) {}

  public async track(event: string, properties: { [key: string]: any } = {}, userId?: string): Promise<void> {
    try {
      const eventToSend = { event, userId, properties };

      await this.httpClient.post<void>(`${sharedEnvironment.api.url}/analytics/track`, eventToSend).pipe(first()).toPromise();
    } catch (error) {
      console.error(`📃 ~ MicroserviceAnalyticsService service ~ Track Error ~ ${event}`, properties);
    }
    return;
  }

  public async page(page: string, properties: { url: string }): Promise<void> {
    try {
      const eventToSend = { page };

      await this.httpClient.post<void>(`${sharedEnvironment.api.url}/analytics/page-track`, eventToSend).pipe(first()).toPromise();
    } catch (error) {
      console.error(`📃 ~ MicroserviceAnalyticsService service ~ Page Error ~ ${event}`, properties);
    }
    return;
  }

  public async identify(userId: string, properties: UserAnalyticsInterface, params: any = {}): Promise<void> {
    if (!userId || !properties) {
      throw new Error('userId and userData are required');
    }
    try {
      const eventToSend = { userId, properties: { ...properties, params } };
      await this.httpClient.post<void>(`${sharedEnvironment.api.url}/analytics/identify`, eventToSend).pipe(first()).toPromise();
    } catch (error) {
      console.error(`📃 ~ MicroserviceAnalyticsService service ~ Identify Error ~ ${event}`, userId, {
        userId,
        properties: { ...properties, params },
      });
    }
    return;
  }
}
