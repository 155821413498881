import { ETranslatesApps } from '../interfaces/utils/translates-apps.enum';
import { AngularFireDatabase } from '@angular/fire/database';
import { TranslateLoader } from '@ngx-translate/core';
import _ from 'lodash';
import { from, Observable } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';

import { LocalTranslationService } from './localTranslationService.service';

export class TranslateApiLoader implements TranslateLoader {
  constructor(
    private realtimedb: AngularFireDatabase,
    private localTranslationService: LocalTranslationService,
    public app: Array<ETranslatesApps>,
  ) {}

  public getTranslation(lang: string): Observable<unknown> {
    this.localTranslationService.getLocalTranslations(lang);

    return this.getRemoteTranslation(lang);
  }

  private getRemoteTranslation(lang: string): Observable<unknown> {
    const langPromises = [];

    this.app.forEach((app: string) => {
      langPromises.push(this.realtimedb.object(`lang/${app}/${lang}`).valueChanges().pipe(first()).toPromise());
    });

    return from(Promise.all(langPromises)).pipe(
      map((translation) => {
        let mergedTranslation = {};
        translation.forEach((l) => {
          mergedTranslation = _.merge(l, mergedTranslation);
        });
        return mergedTranslation;
      }),
      tap((translation: unknown) => {
        this.localTranslationService.setLocalTranslations(lang, translation);
      }),
    );
  }
}
